import React, { FC } from "react";
import classNames from "clsx";

import { ReactComponent as GithubIcon } from "../icons/social/github.svg";
import { ReactComponent as TelegramIcon } from "../icons/social/telegram.svg";
import { ReactComponent as TwitterIcon } from "../icons/social/twitter.svg";
import { ReactComponent as MediumIcon } from "../icons/social/medium.svg";
// import { ReactComponent as FacebookIcon } from "../icons/social/facebook.svg";
// import { ReactComponent as LinkedinIcon } from "../icons/social/linkedin.svg";

type SocialLinksProps = {
  full?: boolean;
  className?: string;
};

const SocialLinks: FC<SocialLinksProps> = ({ className, full }) => (
  <ul className={classNames("flex", className)}>
    <li>
      <a
        href="https://github.com/wigwamapp"
        target="_blank"
        rel="noopener noreferrer"
        className="p-1 flex justify-center items-center text-brand-primary hover:text-brand-accent transition duration-150 ease-in-out"
        aria-label="Github"
      >
        <GithubIcon className="w-8 h-8 fill-current" />
      </a>
    </li>

    <li className={classNames(full ? "ml-2" : "ml-1")}>
      <a
        href="https://t.me/wigwamapp"
        target="_blank"
        rel="noopener noreferrer"
        className="p-1 flex justify-center items-center text-brand-primary hover:text-brand-accent transition duration-150 ease-in-out"
        aria-label="Telegram"
      >
        <TelegramIcon className="w-8 h-8 fill-current" />
      </a>
    </li>

    <li className={classNames(full ? "ml-2" : "ml-1")}>
      <a
        href="https://twitter.com/wigwam_app"
        target="_blank"
        rel="noopener noreferrer"
        className="p-1 flex justify-center items-center text-brand-primary hover:text-brand-accent transition duration-150 ease-in-out"
        aria-label="Twitter"
      >
        <TwitterIcon className="w-8 h-8 fill-current" />
      </a>
    </li>

    {full && (
      <li className={classNames(full ? "ml-2" : "ml-1")}>
        <a
          href="https://wigwamapp.medium.com/"
          target="_blank"
          rel="noopener noreferrer"
          className="p-1 flex justify-center items-center text-brand-primary hover:text-brand-accent transition duration-150 ease-in-out"
          aria-label="Facebook"
        >
          <MediumIcon className="w-8 h-8 fill-current" />
        </a>
      </li>
    )}

    {/* {full && (
      <li className={classNames(full ? "ml-2" : "ml-1")}>
        <a
          href="https://www.facebook.com/groups/vigvamapp"
          target="_blank"
          rel="noopener noreferrer"
          className="p-1 flex justify-center items-center text-brand-primary hover:text-brand-accent transition duration-150 ease-in-out"
          aria-label="Facebook"
        >
          <FacebookIcon className="w-8 h-8 fill-current" />
        </a>
      </li>
    )}

    {full && (
      <li className={classNames(full ? "ml-2" : "ml-1")}>
        <a
          href="https://www.linkedin.com/company/vigvamapp"
          target="_blank"
          rel="noopener noreferrer"
          className="p-1 flex justify-center items-center text-brand-primary hover:text-brand-accent transition duration-150 ease-in-out"
          aria-label="Linkedin"
        >
          <LinkedinIcon className="w-8 h-8 fill-current" />
        </a>
      </li>
    )} */}
  </ul>
);

export default SocialLinks;
