import React, { SVGProps } from "react";

const WigwamIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="64"
    height="40"
    viewBox="0 0 642 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M352.153 0H641.149L416.356 80.3828L352.153 0Z"
      fill="url(#paint0_linear_639_16175)"
    />
    <path
      d="M241.106 300.478L0 0H160.766L321.531 200.375L241.106 300.478Z"
      fill="url(#paint1_linear_639_16175)"
    />
    <path
      d="M241.148 299.979L417.158 79.9662L641.148 0L321.199 400L241.148 299.979Z"
      fill="url(#paint2_linear_639_16175)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_639_16175"
        x1="556.373"
        y1="-71.2835"
        x2="423.697"
        y2="86.5778"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00121575" stopColor="#FF002D" />
        <stop offset="1" stopColor="#FF7F44" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_639_16175"
        x1="211.365"
        y1="42.3492"
        x2="96.5605"
        y2="179.579"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00121575" stopColor="#FF002D" />
        <stop offset="1" stopColor="#FF7F44" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_639_16175"
        x1="599.741"
        y1="-34.7775"
        x2="270.544"
        y2="357.544"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00121575" stopColor="#FF002D" />
        <stop offset="1" stopColor="#FF7F44" />
      </linearGradient>
    </defs>
  </svg>
);

export default WigwamIcon;
