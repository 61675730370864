import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import classNames from "clsx";

import NewButton from "./NewButton";
import { useExtension } from "../useExtension";

const { detect } = require("detect-browser");

const InstallApp: FC<{
  withMaxWidth?: boolean;
  className?: string;
  onClick?: (e: any) => void;
}> = ({ withMaxWidth = true, className, onClick }) => {
  const ext = useExtension();

  const [browser, setBrowser] = useState<
    "firefox" | "safari" | "chrome" | null
  >(null);

  const handleButtonClick = useCallback(
    (evt) => {
      if (ext) {
        ext.openApp();
      }

      onClick?.(evt);
    },
    [ext, onClick],
  );

  useEffect(() => {
    const detectedBrowser = detect();
    switch (detectedBrowser && detectedBrowser.name) {
      case "firefox":
        setBrowser("firefox");
        break;

      case "safari":
        setBrowser("safari");
        break;

      default:
        setBrowser("chrome");
    }
  }, []);

  const link = useMemo(
    () => (ext ? undefined : getLink(browser)),
    [browser, ext],
  );

  return (
    <NewButton
      href={link}
      className={classNames(withMaxWidth ? "w-[13.75rem]" : "", className)}
      onClick={handleButtonClick}
      disabled={!browser || browser === "safari"}
    >
      {ext
        ? "Open App"
        : browser === "safari"
        ? "Safari not supported"
        : "Install app"}
    </NewButton>
  );
};

export default InstallApp;

const getLink = (browser: string | null) => {
  if (!browser || browser === "safari") {
    return undefined;
  }

  if (browser === "firefox") {
    return "https://addons.mozilla.org/en-US/firefox/addon/vigvam/";
  }

  return "https://chromewebstore.google.com/detail/wigwam-%E2%80%94-web3-wallet/lccbohhgfkdikahanoclbdmaolidjdfl";
};
