import { useCallback, useEffect, useState } from "react";

export function useExtension() {
  const [info, setInfo] = useState(extInfo);

  useEffect(() => {
    window.addEventListener(WIGWAM_DETECTED, () => setInfo(extInfo));
  }, [setInfo]);

  const openApp = useCallback(() => {
    if (!info) return;

    ["vigvam", "wigwam"].forEach((name) => {
      window.postMessage(
        {
          type: `${name}.openapp`,
          salt: info.salt,
        },
        window.location.origin
      );
    })
  }, [info]);

  return info
    ? {
        info,
        openApp,
      }
    : null;
}

const WIGWAM_DETECTED = "wigwam_detected";

const CHROME_EXT_ID = "lccbohhgfkdikahanoclbdmaolidjdfl";
const FIREFOX_EXT_ID_LEGACY = "webextension@vigvam.app";
const FIREFOX_EXT_ID = "webextension@wigwam.app";

type ExtInfo = {
  extId: string;
  version: string;
  salt: string;
};

let extInfo: ExtInfo | undefined;

if (typeof window !== "undefined") {
  const usp = new URLSearchParams(window.location.search);

  const code = usp.get("c");
  if (code && !localStorage.getItem("betatest_promocode")) {
    localStorage.setItem("betatest_promocode", code);
  }

  window.addEventListener(
    "message",
    (evt) => {
      if (
        evt.source === window &&
        evt.origin === window.location.origin &&
        (evt.data?.type === "wigwam.version" || evt.data?.type === "vigvam.version")
      ) {
        const { extId, version, salt } = evt.data;

        if (
          process.env.NODE_ENV === "development" ||
          extId === CHROME_EXT_ID ||
          extId === FIREFOX_EXT_ID ||
          extId === FIREFOX_EXT_ID_LEGACY
        ) {
          extInfo = { extId, version, salt };
          window.dispatchEvent(new CustomEvent(WIGWAM_DETECTED));

          // const data: Record<string, any> = {};

          // const promocode = localStorage.getItem("betatest_promocode");
          // if (promocode) data.betatestPromocode = promocode;

          // window.postMessage(
          //   {
          //     type: "vigvam.reply",
          //     salt,
          //     data,
          //   },
          //   window.location.origin
          // );
        }
      }
    },
    false
  );
}
