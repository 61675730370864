import React, {ButtonHTMLAttributes, forwardRef, ForwardedRef, AnchorHTMLAttributes} from "react";
import classNames from "clsx";
import { GatsbyLinkProps, Link } from "gatsby";

type NewButtonProps = {
  theme?: "primary" | "secondary" | "tertiary";
  disabled?: boolean;
} & (ButtonHTMLAttributes<HTMLButtonElement> | GatsbyLinkProps<any> | AnchorHTMLAttributes<HTMLAnchorElement>);

const NewButton = forwardRef<HTMLElement, NewButtonProps>(({
  theme = "primary",
  className,
  children,
                                                             disabled= false,
  ...rest
}, ref) => {
  const classNamesList = classNames(
    "py-3 px-4 min-w-[10rem]",
    "text-brand-light text-base font-bold",
    theme === "primary" && "bg-buttonaccent bg-opacity-90",
    theme === "secondary" && "bg-brand-tertiary bg-opacity-10",
    "rounded-[.375rem]",
    "inline-flex justify-center",
    "transition ease-in-out duration-200",
    "focus:outline-none",
    disabled && "cursor-default",
    disabled && "opacity-60",
    theme === "primary" && [
      !disabled && "hover:bg-opacity-100 hover:shadow-buttonaccent",
      !disabled && "focus:bg-opacity-100 focus:shadow-buttonaccent",
      !disabled && "active:bg-opacity-70 active:shadow-none",
    ],
    (theme === "secondary" || theme === "tertiary") && [
      "hover:bg-brand-darklight hover:bg-opacity-100 hover:shadow-buttonsecondary",
      "focus:bg-brand-darklight focus:bg-opacity-100 focus:shadow-buttonsecondary",
      "active:bg-brand-tertiary active:text-brand-light/60 active:bg-opacity-10 active:shadow-none",
    ],
    className
  );

  if ("href" in rest && rest.href) {
    if (disabled) {
      return (
        <span className={classNamesList}>
          {children}
        </span>
      )
    }
    return (
      <a
        ref={ref as any}
        target="_blank"
        rel="nofollow noreferrer"
        className={classNamesList}
        {...rest}
      >
        {children}
      </a>
    );
  }

  if ("to" in rest) {
    if (disabled) {
      return (
        <span className={classNamesList}>
          {children}
        </span>
      )
    }
    return (
      <Link ref={ref as any} className={classNamesList} {...(rest as GatsbyLinkProps<any>)}>
        {children}
      </Link>
    );
  }

  return (
    <button ref={ref as ForwardedRef<HTMLButtonElement>} className={classNamesList} {...(rest as ButtonHTMLAttributes<HTMLButtonElement>)}>
      {children}
    </button>
  );
});

export default NewButton;
