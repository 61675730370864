import React, { FC, useState, useRef, useEffect, useCallback } from "react";
import { Link } from "gatsby";

import WigwamIcon from "../icons/Wigwam";
import InstallApp from "./InstallApp";

const NavLinks = [
  {
    link: "/",
    title: "Overview",
  },
  {
    link: "/blog",
    title: "Blog",
  },
  {
    link: "/about",
    title: "About",
  },
  {
    link: "/security",
    title: "Security",
  },
  {
    link: "/help",
    title: "Help",
  },
];

const Header: FC = () => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  const mobileNav = useRef<HTMLElement>(null);

  const closeMobileNav = useCallback(() => {
    setMobileNavOpen(false);
  }, [setMobileNavOpen]);

  // close the mobile menu on click outside
  useEffect(() => {
    const clickHandler: EventListener = ({ target }) => {
      if (!mobileNav.current) return;
      if (!mobileNavOpen || mobileNav.current.contains(target as Node)) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close the mobile menu if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!mobileNavOpen || keyCode !== 27) return;
      setMobileNavOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <header className="fixed top-0 w-full z-30">
      <div className="bg-[#07081B]/[.05] backdrop-blur-md">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 border-b border-brand-tertiary/5">
          <div className="flex items-center justify-between h-16 md:h-20">
            {/* Site branding */}
            <div className="flex-shrink-0 mr-4">
              {/* Logo */}
              <Link to="/" className="flex items-center" aria-label="Wigwam">
                <WigwamIcon />

                <span className="ml-3 text-2xl font-black">Wigwam</span>
              </Link>
            </div>

            {/* Desktop navigation */}
            <nav className="hidden md:flex md:flex-grow">
              {/* Desktop menu links */}
              <ul className="flex flex-grow justify-end flex-wrap items-center">
                {NavLinks.map(({ link, title }, i) => (
                  <li key={i}>
                    <Link
                      to={link}
                      className="text-gray-300 hover:text-gray-200 px-4 py-2 flex items-center transition duration-150 ease-in-out"
                      activeStyle={{ color: "white", fontWeight: "bold" }}
                    >
                      {title}
                    </Link>
                  </li>
                ))}
              </ul>

              {/* Desktop sign in links */}
              <ul className="flex flex-grow justify-end flex-wrap items-center">
                <li>
                  <InstallApp withMaxWidth={false} />
                </li>
              </ul>
            </nav>

            {/* Mobile menu */}
            <div className="md:hidden">
              {/* Hamburger button */}
              <button
                className={`hamburger ${mobileNavOpen && "active"}`}
                aria-controls="mobile-nav"
                aria-expanded={mobileNavOpen}
                onClick={() => setMobileNavOpen(!mobileNavOpen)}
              >
                <span className="sr-only">Menu</span>
                <svg
                  className="w-6 h-6 fill-current text-gray-300 hover:text-gray-200 transition duration-150 ease-in-out"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect y="4" width="24" height="2" rx="1" />
                  <rect y="11" width="24" height="2" rx="1" />
                  <rect y="18" width="24" height="2" rx="1" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*Mobile navigation */}
      <nav
        id="mobile-nav"
        ref={mobileNav}
        className="md:hidden absolute top-[120%] z-20 left-0 w-full px-4 sm:px-6 overflow-hidden transition-all duration-300 ease-in-out"
        style={
          mobileNavOpen
            ? { maxHeight: mobileNav.current?.scrollHeight, opacity: 1 }
            : { maxHeight: 0, opacity: 0.8 }
        }
      >
        <ul className="bg-[#07081B]/[.05] border border-brand-tertiary/[.15] backdrop-blur-xl px-6 py-4 text-lg rounded-[.625rem]">
          {NavLinks.map(({ link, title }, i) => (
            <li key={i}>
              <Link
                to={link}
                className="flex text-gray-300 hover:text-gray-200 py-2"
                activeStyle={{ color: "white", fontWeight: "bold" }}
              >
                {title}
              </Link>
            </li>
          ))}

          <li className="py-2 my-2 border-t border-brand-tertiary/[.07]" />

          <li className="pb-2">
            <InstallApp
              withMaxWidth={false}
              className="w-full !py-2"
              onClick={closeMobileNav}
            />
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
