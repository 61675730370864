import React, { FC } from "react";
import { Link } from "gatsby";

import WigwamIcon from "../icons/Wigwam";
import SocialLinks from "./SocialLinks";

const CURRENT_YEAR = new Date().getFullYear();

const Footer: FC = () => {
  return (
    <footer>
      <div className="py-12 md:py-16">
        <div className="max-w-6xl mx-auto px-4 sm:px-6">
          {/* Top area: Blocks */}
          <div className="grid md:grid-cols-12 gap-8 lg:gap-20 mb-8 md:mb-12">
            {/* 1st block */}
            <div className="md:col-span-4 lg:col-span-5">
              <div className="mb-2">
                {/* Logo */}
                <Link
                  to="/"
                  className="inline-flex items-center"
                  aria-label="Wigwam"
                >
                  <WigwamIcon />

                  <span className="ml-3 text-2xl font-black">Wigwam</span>
                </Link>
              </div>
              <div className="text-gray-400">
              Wigwam is a browser extension that allows you to manage your
                wallets, store and move funds, and interact with decentralized
                applications.
              </div>
            </div>

            {/* 2nd, 3rd and 4th blocks */}
            <div className="md:col-span-8 lg:col-span-7 grid sm:grid-cols-3 gap-8">
              {/* 2nd block */}
              <div className="text-sm">
                <h6 className="text-gray-200 font-medium mb-1">Product</h6>
                <ul>
                  <li className="mb-1">
                    <Link
                      to="/"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Overview
                    </Link>
                  </li>
                  <li className="mb-1">
                    <a
                      href="https://chrome.google.com/webstore/detail/vigvam-%E2%80%94-web-30-wallet/lccbohhgfkdikahanoclbdmaolidjdfl"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Chrome Extension
                    </a>
                  </li>
                  <li className="mb-1">
                    <a
                      href="https://addons.mozilla.org/firefox/addon/vigvam/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Firefox Addon
                    </a>
                  </li>
                  <li className="mb-1">
                    <Link
                      to="/help"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Help
                    </Link>
                  </li>
                </ul>
              </div>

              {/* 3rd block */}
              <div className="text-sm">
                <h6 className="text-gray-200 font-medium mb-1">Resource</h6>
                <ul>
                  <li className="mb-1">
                    <Link
                      to="/blog"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Blog
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      to="/terms"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Terms of Use
                    </Link>
                  </li>
                  <li className="mb-1">
                    <Link
                      to="/privacy"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Privacy Policy
                    </Link>
                  </li>
                </ul>
              </div>

              {/* 4th block */}
              <div className="text-sm">
                <h6 className="text-gray-200 font-medium mb-1">Company</h6>
                <ul>
                  <li className="mb-1">
                    <Link
                      to="/about"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      About
                    </Link>
                  </li>

                  <li className="mb-1">
                    <Link
                      to="/contact"
                      className="text-gray-400 hover:text-gray-100 transition duration-150 ease-in-out"
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Bottom area */}
          <div className="md:flex md:items-center md:justify-between">
            {/* Social links */}
            <SocialLinks full className="mb-4 md:order-1 md:ml-2 md:mb-0" />

            {/* Copyrights note */}
            <div className="text-gray-400 text-sm mr-4">
              {CURRENT_YEAR} Wigwam App. Open source.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
